import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://1b63acec2ffc067c20ee7e2794ecd44d@o448786.ingest.us.sentry.io/4508451165306880",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});